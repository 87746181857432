html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  letter-spacing: normal !important;
}

b,
strong {
  font-weight: 700;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}
body {
  background-color: #eee;
  color: $black-color;
  margin: 0;
  font-size: 1rem;
  text-align: left;
}

// Prevent highlight on mobile
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  &:focus {
    outline: 0;
  }
}

a {
  color: $link-color;
  text-decoration: none;
  background-color: transparent;
  &:hover,
  &:focus {
    color: darken($link-color, 5%);
    text-decoration: none;
  }
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: $checkboxes-text-color;
  font-weight: 400;
}
