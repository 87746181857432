* {
    font-family: 'Poppins', sans-serif !important;
    text-rendering: optimizeLegibility;
    box-shadow: none !important;
}

.fa,
.far,
.fas {
    font-family: 'Font Awesome 5 Free' !important;
}

.fab {
    font-family: 'Font Awesome 5 Brands' !important;
}

body {
    margin: 0;
    padding: 0;
    background-color: #fbf9f6;
}

.MuiDialog-paperWidthSm {
    background-color: transparent;
}

.tooltip-toggle {
    cursor: default;
    * {
        pointer-events: none;
    }
}

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}

// CUSTOM
.network-list {
    >div:not(:first-child) {
        margin-left: 30px;
    }
    &_item {
        &:hover {
            background: #3385FF;
        }
        cursor: pointer;
        background: rgba(0, 0, 0, 0.6);
        &--active {
            background: #3385FF;
        }
    }
}

.TextField-without-border-radius fieldset {
    border-radius: 30px;
}

.TextField-without-border-radius input {
    font-size: 14px;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    padding-bottom: 10px;
}

.accordion-light.Mui-expanded {
    background-color: #F5F4F8;
    >* {
        background-color: transparent;
    }
    &.MuiAccordionSummary-content {
        margin-bottom: 0;
    }
}

.accordion-dark.Mui-expanded {
    background-color: rgba(255, 255, 255, 0.06);
    >* {
        background-color: transparent;
    }
    &.MuiAccordionSummary-content {
        margin-bottom: 0;
    }
}

.MuiSelect-select:focus {
    background-color: transparent;
}

.bootstrap-row {
    flex-wrap: wrap;
    .bootstrap-column {
        width: 100%;
    }
    .bootstrap-column--left {
        order: 2;
        padding: 15px 5px 0px 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .bootstrap-column--right {
        order: 1;
    }
}

.checkbox-label * {
    font-size: 14px;
    &.MuiFormControlLabel-root {
        margin-right: 0;
    }
}

.responsive-stat {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 15px 0 15px;
}

.responsive-stat p:first-child {
    order: 2;
}

.responsive-title {
    justify-content: space-between;
    display: flex;
    flex-grow: 1;
    margin-right: 0;
    >div {
        width: auto;
        &:last-child {
            flex-direction: column;
            display: flex;
            a {
                margin-right: 0px;
                &:first-child {
                    margin-bottom: 8px;
                }
            }
        }
    }
}

.responsive-title_actions {
    display: 'flex';
    margin-top: '6px';
}

.responsive-background {
    width: 100%;
}

.token-price-wrapper {
    padding: 0 6px;
    span:nth-child(2n) {
        font-size: 1rem;
    }
    span:nth-child(2n + 1) {
        font-size: 0.9rem;
    }
}

.header-prices {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.dashboard-title__tvl {
    margin-right: 20px;
}

.dashboard-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    h1 {
        margin: 0;
        font-size: 18px;
    }
}

.logo-title {
    h3 {
        font-size: 20;
    }
    width: 100%;
}
.not-mobile {
    display: none;
}
.only-mobile {
    .MuiAccordionDetails-root {
        flex-wrap: wrap;
    }
    .MuiAccordionSummary-root, .MuiAccordionDetails-root {
        padding: 0;
    }
    &::before {
        display: none;
    }
    .MuiGrid-item:nth-child(6) {
        padding: 8px 0;
    }
    .MuiGrid-item:nth-child(8) {
        padding: 8px 0;
    }
    .MuiGrid-item:nth-child(10) {
        padding: 8px 0;
    }
    .MuiGrid-item:nth-child(12) {
        padding: 8px 0;
    }
    .MuiAccordionSummary-expandIcon {
        padding-bottom: 0;
    }
    .MuiAccordionSummary-content {
        margin-bottom: 0;
    }
}

// No media query necessary for xs breakpoint as it's effectively `@media (min-width: 0) { ... }`
@media (min-width: 576px) {
    .only-mobile {
        display: none;
        
    }
    .not-mobile {
        display: flex;
    }
    .bootstrap-row {
        .bootstrap-column--left {
            order: 1;
            padding: 0;
        }
        .bootstrap-column--right {
            order: 2;
        }
        .bootstrap-column {
            width: auto;
        }
    }
    .responsive-background {
        width: auto;
    }
    .logo-title {
        h3 {
            font-size: 24px;
        }
        width: calc(100% - 20px);
    }
    .dashboard-title {
        h1 {
            margin: 21.5px 0;
            font-size: 2em;
        }
    }
    .header-prices {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .token-price-wrapper {
        padding: 16px;
        span:nth-child(2n) {
            font-size: 24px;
        }
        span:nth-child(2n + 1) {
            font-size: 16px;
        }
    }
    .responsive-title {
        display: block;
        margin-right: 20px;
        >div {
            width: 100%;
            &:last-child {
                flex-direction: row;
                a {
                    margin-right: 7px;
                    margin-top: 5px;
                    &:first-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .responsive-stat {
        width: auto;
        display: block;
        padding: 0;
        text-align: center;
    }
    .responsive-stat p:first-child {
        order: 1;
    }
    .responsive-stat p:last-child {
        order: 12;
    }
    .checkbox-label {
        font-size: 12px;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {}